import { DatePicker, Modal, Space, Table, Tooltip } from 'antd'
import PopupMessage from 'components/PopupMessageAlert'
import { BIG_COLUMN_WIDTH, DATE_DISPLAY_FORMAT, NORMAL_COLUMN_WIDTH, MIN_COLUMN_WIDTH } from 'constants/dateFormats'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import UserVihcleService from 'services/userVihcleService'
import { ReactComponent as DeleteIcon } from './../../assets/new-icons/Delete2.svg'
import { ReactComponent as EditIcon } from './../../assets/new-icons/Edit.svg'
import { ReactComponent as DefaultXe } from './../../assets/new-icons/Car default.svg'
import './../../assets/scss/typography.scss'
import { VIHCLE_TYPES } from 'constants/global'
import { ReactComponent as InfoIcon } from './../../assets/new-icons/info.svg'
import { ExceptionOutlined } from '@ant-design/icons'
import { ReactComponent as SuccessIcon } from './../../assets/new-icons/successicon.svg'
import { ReactComponent as WarningIcon } from './../../assets/new-icons/warningicon.svg'
import uploadService from 'services/uploadService';
let expiryDate
let expiryDateBHTV
let expiryDateBHTNDS

function VehicleViewTable({ ele, history, removeItem, skip }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalExpiryDate, setModalExpiryDate] = useState(false)
  const [modalExpiryDateBHTV, setModalExpiryDateBHTV] = useState(false)
  const [modalExpiryDateBHTNDS, setModalExpiryDateBHTNDS] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [item, setItem] = useState({})
  const { t: translation } = useTranslation()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    {
      modalExpiryDate && handleUpdateExpiryDate(item)
    }
    {
      modalExpiryDateBHTV && handleUpdateExpiryDateBH(item)
    }
    {
      modalExpiryDateBHTNDS && handleUpdateExpiryDateBH(item)
    }
  }
  const handleViewCriminalDetail = async (record) => {
    const { appUserVehicleId: id, vehicleIdentity } = record
    const dataFilter = {
      filter: {
        customerRecordPlatenumber: vehicleIdentity
      }
    }
    try {
      const result = await uploadService.getCrimeRecords(dataFilter);
      const { isSuccess, data } = result
      if (isSuccess) {
        history.push('/criminal-record-detail', { data: data.data, id });
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const onChange = (date, dateString) => {
    if (dateString) {
      expiryDate = moment(dateString, DATE_DISPLAY_FORMAT).format(DATE_DISPLAY_FORMAT).toString()
    }
  }
  const onChangeBHTV = (date, dateString) => {
    expiryDateBHTV = moment(dateString, DATE_DISPLAY_FORMAT).format('YYYYMMDD')
  }
  const onChangeBHTNDS = (date, dateString) => {
    expiryDateBHTNDS = Number.parseInt(moment(dateString, DATE_DISPLAY_FORMAT).format('YYYYMMDD'))
  }

  const handleUpdateExpiryDate = (ele) => {
    UserVihcleService.updateVihcle({
      id: ele?.appUserVehicleId,
      data: {
        vehicleExpiryDate: expiryDate
      }
    }).then((result) => {
      const { isSuccess, message: rsMess } = result
      if (!isSuccess) {
        if (rsMess) {
          setErrorMessage(translation('update-fail'))
        }
      } else {
        setErrorMessage(translation('update-succ'))
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
    })
  }
  const handleUpdateExpiryDateBH = (ele) => {
    UserVihcleService.updateVihcleExpiryDate({
      id: ele?.appUserVehicleId,
      data: {
        appUserId: ele?.appUserId,
        vehicleIdentity: ele?.vehicleIdentity,
        vehicleExpiryDateBHTNDS: expiryDateBHTNDS,
        vehicleExpiryDateBHTV: expiryDateBHTV
      }
    }).then((result) => {
      const { isSuccess, message: rsMess } = result
      if (!isSuccess) {
        if (rsMess) {
          setErrorMessage(translation('update-fail'))
        }
      } else {
        setErrorMessage(translation('update-succ'))
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
    })
  }

  const columns = [
    {
      title: 'STT',
      key: 'index',
      dataIndex: 'index',
      width: MIN_COLUMN_WIDTH,
      align: 'center',
      render: (_, __, index) => {
        return <div className="viewTableText text-normal">{skip ? skip + index + 1 : index + 1}</div>
      }
    },
    {
      title: 'Biển số xe',
      key: 'vehicleIdentity',
      dataIndex: 'vehicleIdentity',
      width: BIG_COLUMN_WIDTH,
      render: (value, record) => {
        const { vehicleIdentity, vehiclePlateColor } = record
        return (
          <div className="d-flex justify-content-between align-items-center">
            <div>{VIHCLE_TYPES.find((e) => e.value == record?.vehicleType)?.icon || ''}</div>
            <div className="d-flex align-items-center gap-1">
              <div
                className={`color_licensePlates viewTableText text-normal
              ${vehiclePlateColor === 'WHITE' ? 'color_white' : ' '}
              ${vehiclePlateColor === 'BLUE' ? 'color_blue' : ' '}
              ${vehiclePlateColor === 'YELLOW' ? 'color_yellow' : ' '}
              ${vehiclePlateColor === 'RED' ? 'color_red' : ' '}
            `}>
                {vehicleIdentity}
              </div>
              <Tooltip className='bg-white' title={(<div className='bg-white'>
                {record?.vehicleVerifiedInfo === 1 ? (
                  <>
                    <div className='success-verified'><SuccessIcon width={22} height={22} />{translation('verified')}</div>
                  </>
                ) : (
                  <>
                    <div className='warning-verified'><WarningIcon width={15} height={15} />{translation('unverified')}</div>
                  </>
                )}
              </div>)}>
                {record?.vehicleVerifiedInfo === 1 ? (
                  <>
                    <div className='iconVehicleVerifiedInfo'><SuccessIcon width={20} height={20} /></div>
                  </>
                ) : (
                  <>
                    <div className='iconVehicleVerifiedInfo'><WarningIcon width={13} height={13} /></div>
                  </>
                )}
              </Tooltip>
            </div>
          </div>
        )
      }
    },
    {
      title: 'Hạn đăng kiểm',
      key: 'vehicleExpiryDate',
      dataIndex: 'vehicleExpiryDate',
      align: 'center',
      //   width: BIG_COLUMN_WIDTH,
      render: (value, record) => {
        return (
          <div
            className="viewTableText cursor text-normal"
            onClick={() => {
              showModal()
              setItem(record)
              setModalExpiryDate(true)
              setModalExpiryDateBHTV(false)
              setModalExpiryDateBHTNDS(false)
            }}>
            {value ? value : '--/--/--'}
          </div>
        )
      }
    },
    {
      title: 'Hạn BHTV',
      key: 'vehicleExpiryDateBHTV',
      dataIndex: 'vehicleExpiryDateBHTV',
      align: 'center',
      //   width: BIG_COLUMN_WIDTH,
      render: (value, record) => {
        return (
          <div
            className="viewTableText cursor text-normal"
            onClick={() => {
              showModal()
              setItem(record)
              setModalExpiryDate(false)
              setModalExpiryDateBHTV(true)
              setModalExpiryDateBHTNDS(false)
            }}>
            {value ? moment(value, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT) : '--/--/--'}
          </div>
        )
      }
    },
    {
      title: 'Hạn BH TNDS',
      key: 'vehicleExpiryDateBHTNDS',
      dataIndex: 'vehicleExpiryDateBHTNDS',
      align: 'center',
      //   width: BIG_COLUMN_WIDTH,
      render: (value, record) => {
        return (
          <div
            className="viewTableText cursor text-normal"
            onClick={() => {
              showModal()
              setItem(record)
              setModalExpiryDate(false)
              setModalExpiryDateBHTV(false)
              setModalExpiryDateBHTNDS(true)
            }}>
            {value ? moment(value, 'YYYYMMDD').format(DATE_DISPLAY_FORMAT) : '--/--/--'}
          </div>
        )
      }
    },
    {
      title: 'Hạn TBPN',
      key: 'vehicleExpiryDateBHTNDS',
      dataIndex: 'vehicleExpiryDateBHTNDS',
      align: 'center',
      //   width: BIG_COLUMN_WIDTH,
      render: (value, record) => {
        const automatedTrafficFineNotificationRegistered = record?.customerSchedule?.filter((e) => e.notifyType === 1)
        const isAutomatedTrafficFineNotificationRegistered = automatedTrafficFineNotificationRegistered?.length > 0

        const getLargestDate = (schedules) => {
          return schedules.reduce((latest, current) => {
            const currentDate = new Date(current.dateSchedule.split('/').reverse().join('-'))
            return currentDate > latest ? currentDate : latest
          }, new Date('1970-01-01'))
        }

        const largestDate = moment(getLargestDate(automatedTrafficFineNotificationRegistered)).format('DD/MM/YYYY')

        return (
          <div className="">
            {isAutomatedTrafficFineNotificationRegistered ? (
              <>
                <span className="viewTableText text-normal">{largestDate}</span>
              </>
            ) : (
              <Link to={'/automated-traffic-fine-notification/services'} className="viewTableText">
                {translation('register-now')}
              </Link>
            )}
          </div>
        )
      }
    },
    {
      title: 'Cảnh báo',
      key: '',
      dataIndex: '',
      width: '140px',
      align: 'center',
      render: (_, record) => {
        return (
          <div className="viewTableText text-normal">
            {
              record?.hasCrimimal > 0 ? (
                <div className="viewTableWaring d-flex justify-content-between align-items-center" onClick={() => handleViewCriminalDetail(record)}>
                  <ExceptionOutlined
                    style={{
                      color: 'var(--bs-danger)',
                    }}
                    className="text-large cursor"
                  />
                  <div className='cursor'>Có {record?.hasCrimimal} cảnh báo</div>
                </div>
              ) : (
                <div className="viewTableSearch" onClick={() => history.push("/kiemtraphatnguoi")}>Tra cứu</div>
              )
            }
          </div>
        )
      }
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'center',
      //   width: BIG_COLUMN_WIDTH,
      render: (_, record) => {
        return (
          <div className="d-flex justify-content-between">
            <div
              onClick={(e) => {
                e.stopPropagation()
                history.push('/regist-vihcle/' + record?.appUserVehicleId)
              }}
              className="cursor">
              <EditIcon width={28} height={28} className="me-1" />
              {/* {translation('edit')}  */}
            </div>
            <div
              className="cursor"
              style={{ color: 'var(--background-iphone-popup-coler)' }}
              onClick={(e) => {
                e.stopPropagation()
                removeItem(record?.appUserVehicleId)
              }}>
              <DeleteIcon width={28} height={28} className="me-1" />
              {/* {translation('delete')} */}
            </div>
          </div>
        )
      }
    }
  ]

  return (
    <div>
      {ele.length > 0 && <Table dataSource={ele} columns={columns} scroll={{ x: 900 }} pagination={false} />}
      <Modal title="Cập nhật" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {modalExpiryDate && (
          <>
            <div style={{ margin: '0 0 5px 0' }}>{translation('end-date-dk')}</div>
            <Space direction="vertical" size={12}>
              <DatePicker name="expirydate" placeholder={translation('handk')} onChange={onChange} format={DATE_DISPLAY_FORMAT} />
            </Space>
          </>
        )}
        {modalExpiryDateBHTV && (
          <>
            <div style={{ margin: '15px 0 5px 0' }}>{translation('end-date-tv')}</div>
            <Space direction="vertical" size={12}>
              <DatePicker name="expirydateBHTV" placeholder={translation('date-tv')} onChange={onChangeBHTV} format={DATE_DISPLAY_FORMAT} />
            </Space>
          </>
        )}
        {modalExpiryDateBHTNDS && (
          <>
            <div style={{ margin: '15px 0 5px 0' }}>{translation('end-date-tnds')}</div>
            <Space direction="vertical" size={12}>
              <DatePicker name="expirydateBHTNDS" placeholder={translation('date-tnds')} onChange={onChangeBHTNDS} format={DATE_DISPLAY_FORMAT} />
            </Space>
          </>
        )}
      </Modal>
      {errorMessage && (
        <PopupMessage
          isModalOpen={errorMessage}
          onClose={() => {
            setErrorMessage(undefined)
          }}
          text={errorMessage}></PopupMessage>
      )}
    </div>
  )
}

export default VehicleViewTable
