import React, { Fragment, useEffect, useState } from 'react'

import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Row, Col, Empty, DatePicker, Input } from 'antd'

import './index.scss'
import LoadingPopup from 'components/LoadingPopup'
import OrderHistoryView from './orderHistoryView'
import { DATE_DISPLAY_FORMAT } from 'constants/dateFormats'
import { StickyHeaderContainer } from 'components/elements/header'
import BasicTablePaging from 'components/BasicComponent/BasicTablePaging'
import OrderServices from 'services/orderService'

const DEFAULT_FILTER = {
  filter: {},
  skip: 0,
  limit: 20,
  order: {
    key: 'createdAt',
    value: 'desc',
  }
}

const OrderHistory = () => {
  const { t: translation } = useTranslation()

  const [isVisible, setIsVisible] = useState(false)
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [dataList, setDataList] = useState({ data: [], total: 0 })
  const [isReload, setReload] = useState(false)

  function onChooseStartDate(value) {
    const day = moment(value).format(DATE_DISPLAY_FORMAT)

    const newData = {
      ...DEFAULT_FILTER,
      startDate: day,
      endDate: day,
    }

    setReload(true)
    setFilter(newData)

    if (day === "Invalid date") {
      delete newData.startDate
      delete newData.endDate
    }

    getData(newData)
  }

  function getData(filter) {
    setIsVisible(true)
    OrderServices.getOrders(filter).then((result) => {
      const { statusCode, data } = result
      setIsVisible(false)

      if (statusCode === 200) {
        setDataList(data)
      } else {
        //lấy danh sách lỗi không cần show lỗi
        return
      }
    })
  }

  const handleChangePage = (pageNum) => {
    const skip = pageNum * filter.limit - filter.limit
    const newFilter = {
      ...filter,
      skip
    }

    setFilter(newFilter)

    getData(newFilter)
  }
  useEffect(() => {
    getData(filter)
  }, [])

  return (
    <div className='w-100 order-history-main'>
      <StickyHeaderContainer showLogo={false} showArrowLeft={true} title={translation('my-order')} color="#051945" backgroundColor="white" borderColor="#dddddd" />
      {isVisible && (
        <LoadingPopup />
      )}
      <div className="d-flex flex-column" style={{ height: '100%' }}>
        <div>
          <div className="pd-30-15 " style={{ maxWidth: 600, margin: 'auto' }}>
            <div style={{ maxWidth: 600, height: '' }}>
              <Row>
                <Col xs={24} md={11}>
                  <DatePicker
                    onChange={(event) => {
                      const value = event
                      onChooseStartDate(value)
                    }}
                    format={DATE_DISPLAY_FORMAT}
                    placeholder="Chọn ngày bắt đầu"
                    className='startDate'
                  />
                </Col>
                <Col xs={0} md={2}></Col>
                <Col xs={24} md={11}>
                  <Input
                    disabled
                    value={filter.endDate}
                    className="login__input booking-input"
                    placeholder="Ngày kết thúc"
                    type="text"
                    size="normal"
                  />
                </Col>
              </Row>
              <div className="mt-3">
                {dataList?.data?.length > 0 ? dataList?.data?.map((el, key) => (
                  <div
                    key={key}
                    className="box-order-history"
                    style={{ border: el?.vehicleVerifiedInfo === 1 ? '1px solid rgba(0, 104, 255, 0.3)' : '1px solid #ededed' }}
                  >
                    <Fragment key={key}>
                      <OrderHistoryView element={el} id={key} skip={filter.skip} />
                    </Fragment>
                  </div>
                )) : <Empty style={{ margin: "auto" }} description="Chưa có dữ liệu" />}
              </div>
            </div>
            <div className="pd-15" style={{ maxWidth: 600, margin: 'auto' }}>
              <>
                <BasicTablePaging isReload={isReload} handlePaginations={handleChangePage} count={dataList?.data?.length < filter.limit}></BasicTablePaging>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderHistory
